import React from 'react'
import { RoosterLijnType } from '.'
import { Job as JobType } from '../Persoon'
import { DateTime } from 'luxon'

const RoosterLijn = ({ roosterlijn }: { roosterlijn: RoosterLijnType }) => {
  return (
    <tr className="border border-primary">
      <td className="text-xl p-2">
        {roosterlijn.persoon.profile.voornaam + ' ' + roosterlijn.persoon.profile.achternaam}
      </td>
      {roosterlijn.week.map((dag) => (
        <td key={dag.dag.toISO()} className="text-center p-1">
          <Jobs jobs={dag.jobs} />
        </td>
      ))}
    </tr>
  )
}
export default RoosterLijn

///

const Jobs = ({ jobs }: { jobs: JobType[] }) => {
  return (
    <div>
      {jobs.map((job, index) => (
        <Job key={index} job={job} />
      ))}
    </div>
  )
}

const Job = ({ job }: { job: JobType }) => {
  if (job.code) return <div className="">{job.code}</div>

  return (
    <div className="border border-primary p-1 bg-gray-200 ">
      <div className="text-xl text-white bg-primary">
        {DateTime.fromISO(job.beginuur).toFormat('HH:mm')}-
        {DateTime.fromISO(job.einduur).toFormat('HH:mm')}
      </div>
      <div className="font-semibold">{job.middel}</div>
      <div>{job.description}</div>
      {job.opmerking && <div className="bg-lime-400 p-1">{job.opmerking}</div>}
      {job.buitenland && <div className="bg-red-400 p-1">Buitenland</div>}
      {job.codeAntwerpen && <div className="bg-red-400 p-1">Antwerpen</div>}
      {job.collegas && job.collegas.length && <div>Met: {job.collegas.join(' ')}</div>}
      <div className="text-black/50">{job.project}</div>
    </div>
  )
}
