import React, { useEffect, useState } from 'react'
import Header from './Header'
import Loading from '../components/Loading'
import { BASE_URL } from '../App'
import WeekNavigatie from './WeekNavigatie'
import { DateTime } from 'luxon'
import { PersoonsType } from '../Personen'
import { DagPlanning, Job } from '../Persoon'
import RoosterLijn from './RoosterLijn'

const Rooster = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [actieveCategorie, setActieveCategorie] = useState('camera')
  const [datum, setDatum] = useState(DateTime.now())
  const [rooster, setRooster] = useState<RoosterLijnType[]>([])

  useEffect(() => {
    const getRooster = async () => {
      try {
        const params = new URLSearchParams({
          slot: '4a9b',
          groep: actieveCategorie,
          datum: datum.toISODate() || '',
        })
        const response = await fetch(BASE_URL + '/api/v1/planning/getrooster?' + params, {
          method: 'GET',
        })
        if (response.status !== 200) {
          console.log('ERROR', response)
          setErrorMessage(response.statusText)
          setIsLoading(false)
          return
        }
        setErrorMessage('')

        const result = await response.json()
        if (!result.users) setErrorMessage('Geen users gevonden')
        if (!result.diensten) setErrorMessage('Geen diensten gevonden')

        const rooster = sortDienstenTotRooster({
          users: result.users,
          diensten: result.diensten,
          datum,
        })
        setRooster(rooster)
        setIsLoading(false)
      } catch (error) {
        console.log('ERROR', error)
      }
    }
    setIsLoading(true)
    getRooster()
  }, [actieveCategorie, datum])

  return (
    <div className="">
      <div>
        <WeekNavigatie datum={datum} setDatum={setDatum} />
        <Header actieveCategorie={actieveCategorie} setActieveCategorie={setActieveCategorie} />
        {errorMessage.length ? (
          <div className="bg-red-700 text-white p-3 rounded text-3xl text-center">
            Hier ging iets mis: {errorMessage}
          </div>
        ) : null}
        {isLoading || !rooster.length ? (
          <Loading />
        ) : (
          <div>
            <table className="table-auto bg-gray-100">
              <thead className="bg-primary text-white">
                <tr>
                  <th className="p-2">Naam</th>
                  {rooster[0].week.map((dag) => (
                    <th className="p-4" key={dag.dag.toISO()}>
                      {dag.dag.toFormat('ccc dd/LL/yy')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rooster.map((roosterlijn) => (
                  <RoosterLijn key={roosterlijn.persoon._id} roosterlijn={roosterlijn} />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}
export default Rooster

///

export type RoosterLijnType = {
  persoon: PersoonsType
  week: Week
}
type Week = {
  dag: DateTime
  jobs: Job[]
}[]

const sortDienstenTotRooster = ({
  users,
  diensten,
  datum,
}: {
  users: PersoonsType[]
  diensten: DagPlanning[]
  datum: DateTime
}) => {
  const beginDatum = datum.startOf('week')
  const rooster: RoosterLijnType[] = []
  users.forEach((persoon) => {
    const week: Week = []
    for (let i = 0; i < 7; i++) {
      const dag = beginDatum.plus({ days: i })
      const dienst = diensten.find(
        (d) =>
          DateTime.fromISO(d.datum).toISODate() === dag.toISODate() && d.gebruiker === persoon._id
      )
      week.push({ dag, jobs: dienst && dienst.jobs ? dienst.jobs : [] })
    }
    rooster.push({ persoon, week })
  })
  return rooster
}
