import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

const WeekNavigatie = ({ datum, setDatum }: { datum: DateTime; setDatum: Function }) => {
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/')
  }

  const setDezeWeek = () => {
    setDatum(DateTime.now())
  }
  const setVorigeWeek = () => {
    setDatum(datum.minus({ day: 7 }))
  }
  const setVolgendeWeek = () => {
    setDatum(datum.plus({ day: 7 }))
  }

  return (
    <div className="flex flex-col border border-primary rounded m-1 bg-gray-100 p-2 items-center">
      <div className="text-xl text-center text-primary">
        {/* {persoon ? persoon?.profile.voornaam + ' ' + persoon?.profile.achternaam : '-'} */}
      </div>
      <div className="text-primary text-xl">
        <span className="font-bold">{datum.startOf('week').toFormat('cccc dd/LL/yy')}</span>
        &nbsp;tot&nbsp;
        <span className="font-bold">
          {datum.startOf('week').plus({ day: 6 }).toFormat('cccc dd/LL/yy')}
        </span>
      </div>
      <div className="flex flex-wrap">
        <button
          onClick={goHome}
          className="m-2 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
        >
          <FontAwesomeIcon icon={faHome} className="mx-2" />
          Home
        </button>
        <div>
          <button
            onClick={setVorigeWeek}
            className="m-2 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mx-2" />
            Week terug
          </button>
          <button
            onClick={setDezeWeek}
            className="m-2 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
          >
            Deze Week
          </button>
          <button
            onClick={setVolgendeWeek}
            className="m-2 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
          >
            Week verder
            <FontAwesomeIcon icon={faChevronRight} className="mx-2" />
          </button>
        </div>
      </div>
    </div>
  )
}
export default WeekNavigatie
