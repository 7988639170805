import React from 'react'
import { createHashRouter, Outlet, RouterProvider } from 'react-router-dom'
import './index.css'
import Header from './Header'
import Personen from './Personen'
import Persoon from './Persoon'
import NotFound from './NotFound'
import Rooster from './Rooster'

// export const BASE_URL = 'http://localhost:3000'
export const BASE_URL = 'https://beo.eleventy.be'

const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
    </div>
  )
}

const router = createHashRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <Personen />,
      },
      {
        path: '/person/:id',
        element: <Persoon />,
      },
      {
        path: '/rooster',
        element: <Rooster />,
      },
    ],
  },
])

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  )
}
export default App
