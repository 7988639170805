import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { PersoonsType } from '../Personen'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

const Header = ({
  persoon,
  datum,
  setDatum,
}: {
  persoon: PersoonsType | undefined
  datum: DateTime
  setDatum: Function
}) => {
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/')
  }

  const setDezeWeek = () => {
    setDatum(DateTime.now())
  }
  const setVorigeWeek = () => {
    setDatum(datum.minus({ day: 7 }))
  }
  const setVolgendeWeek = () => {
    setDatum(datum.plus({ day: 7 }))
  }

  return (
    <div className="flex flex-col border border-primary rounded m-1 bg-gray-100 p-2 items-center">
      <div className="text-xl text-center text-primary">
        {persoon ? persoon?.profile.voornaam + ' ' + persoon?.profile.achternaam : '-'}
      </div>
      <div className="text-primary text-center text-xl hidden md:inline-block">
        <div className="font-bold">{datum.startOf('week').toFormat('cccc dd/LL/yy')}</div>
        <div className="font-bold">
          {datum.startOf('week').plus({ day: 6 }).toFormat('cccc dd/LL/yy')}
        </div>
      </div>
      <div className="text-primary text-center text-xl flex-wrap inline-block md:hidden">
        <div className="font-bold">{datum.startOf('week').toFormat('dd/LL/yy')}</div>
        <div className="font-bold">
          {datum.startOf('week').plus({ day: 6 }).toFormat('dd/LL/yy')}
        </div>
      </div>
      <div className="flex flex-wrap">
        <button
          onClick={goHome}
          className="m-2 mr-4 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
        >
          <FontAwesomeIcon icon={faHome} className="mx-2" />
          <span className="hidden lg:inline">Home</span>
        </button>
        <div>
          <button
            onClick={setVorigeWeek}
            className="m-2 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mx-2" />
            <span className="hidden lg:inline">Week terug</span>
          </button>
          <button
            onClick={setDezeWeek}
            className="m-2 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
          >
            Nu
          </button>
          <button
            onClick={setVolgendeWeek}
            className="m-2 bg-primary hover:text-secondary text-white font-semibold py-1 px-2 border border-transparent hover:border-secondary rounded text-center"
          >
            <span className="hidden lg:inline">Week verder</span>
            <FontAwesomeIcon icon={faChevronRight} className="mx-2" />
          </button>
        </div>
      </div>
    </div>
  )
}
export default Header
