import React from 'react'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const goRooster = () => {
    navigate('/rooster')
  }
  const goLijst = () => {
    navigate('/')
  }
  return (
    <div className=" flex justify-between bg-primary text-secondary p-4">
      <div className="px-2 text-2xl">ENG Planning</div>
      <div>
        <button
          onClick={goLijst}
          className="px-8 py-1 mx-2 bg-white text-primary font-semibold border border-gray-700 hover:bg-secondary hover:text-primary rounded"
        >
          Lijst
        </button>
        <button
          onClick={goRooster}
          className="px-6 py-1 mx-2 bg-white text-primary font-semibold border border-gray-700 hover:bg-secondary hover:text-primary rounded"
        >
          Rooster
        </button>
      </div>
      <div className="hidden md:inline-block">v0.6.0</div>
    </div>
  )
}
export default Header
