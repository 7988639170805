import React from 'react'
import { DateTime } from 'luxon'
import { DagPlanning, Job as JobType } from '.'

type Dag = {
  datum: DateTime
  dienst: DagPlanning | undefined
}

const Planning = ({ planning, datum }: { planning: DagPlanning[]; datum: DateTime }) => {
  const vanDatum = datum.startOf('week')
  const dagen: Dag[] = []
  for (let i = 0; i < 7; i++) {
    const datum = vanDatum.plus({ days: i })
    const dienst = planning.find((p) => DateTime.fromISO(p.datum).toISODate() === datum.toISODate())
    dagen.push({ datum, dienst })
  }
  return (
    <div className="flex flex-col items-center w-full max-w-4xl">
      <div className="w-full text-left">
        {dagen.map((dag) => (
          <DagRow dag={dag} key={dag.datum.toISODate()} />
        ))}
      </div>
    </div>
  )
}
export default Planning

///

const DagRow = ({ dag }: { dag: Dag }) => {
  return (
    <div className="border border-primary rounded bg-gray-100 my-4 mx-2 p-2">
      <div className="font-bold text-xl mx-4">{dag.datum.toFormat('ccc dd/LL/yy')}</div>
      <Uren datum={dag.datum} jobs={dag.dienst?.jobs} />
    </div>
  )
}

const Uren = ({ datum, jobs }: { datum: DateTime; jobs: DagPlanning['jobs'] | undefined }) => {
  if (!jobs) return null
  return (
    <div className="px-2 py-4 font-medium flex-col">
      {jobs &&
        jobs.map((job, i) => {
          if (job.code)
            return (
              <div key={i} className="px-6 py-4 font-medium">
                {jobs[0].code}
              </div>
            )
          return (
            <div key={i}>
              <div className="flex flex-wrap my-2">
                <div className="bg-primary text-white p-2 my-2 rounded w-fit basis-1/8">
                  {DateTime.fromISO(job.beginuur).toFormat('HH:mm')}-
                  {DateTime.fromISO(job.einduur).toFormat('HH:mm')}
                </div>
                <Job job={job} />
              </div>
              <div className="flex-grow border-t border-gray-400"></div>
            </div>
          )
        })}
    </div>
  )
}

const Job = ({ job }: { job: JobType }) => {
  if (job.code) return <div className="px-8 py-4 font-medium">{job.code}</div>
  return (
    <div className="px-8">
      <span className="bg-gray-500 text-white p-1">{job.middel}</span>
      <div>
        <span className="text-sm font-light">{job.project?.replaceAll('ENG', '')}</span>
        <span className="text-sm font-light">{job.description?.replaceAll('ENG', '')}</span>
      </div>
      {job.codeAntwerpen && <div className="bg-red-600 text-white m-1 px-2">Antwerpen</div>}
      {job.opmerking && <div className="bg-green-700 text-white m-1 px-2">{job.opmerking}</div>}
      {job.collegas && <div className="">Met: {job.collegas.join(' ')}</div>}
    </div>
  )
}
