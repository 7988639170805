import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Loading = () => (
  <div className="text-center m-10">
    <FontAwesomeIcon icon={faSpinner} className="mx-2 text-primary" spin size="2xl" />
  </div>
)
export default Loading
