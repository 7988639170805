import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  const goHome = () => {
    console.log('going home')
    navigate('/')
  }
  return (
    <div>
      Niet gevonden
      <button className="button p-2" onClick={goHome}>
        Go home
      </button>
    </div>
  )
}
export default NotFound
