import React, { useEffect, useState } from 'react'
import Header from './Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import Loading from '../components/Loading'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../App'

const testPersonen: PersoonsType[] = []

export type PersoonsType = {
  _id: string
  profile: {
    voornaam: string
    achternaam: string
  }
}

const Personen = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [actieveCategorie, setActieveCategorie] = useState('camera')
  const [personen, setPersonen] = useState<PersoonsType[]>([])

  useEffect(() => {
    const getPersonen = async () => {
      try {
        const params = new URLSearchParams({
          slot: '4a9b',
          groep: actieveCategorie,
        })
        const response = await fetch(BASE_URL + '/api/v1/planning/getpersonen?' + params, {
          method: 'GET',
        })
        if (response.status !== 200) {
          console.log('ERROR', response)
          setErrorMessage(response.statusText)
          setIsLoading(false)
          return
        }
        setErrorMessage('')
        setPersonen(testPersonen)

        const users: PersoonsType[] = await response.json()
        setPersonen(users)
        setIsLoading(false)
      } catch (error) {
        console.log('ERROR', error)
      }
    }
    setIsLoading(true)
    getPersonen()
  }, [actieveCategorie])

  return (
    <div className="flex flex-col items-center">
      <div>
        <Header actieveCategorie={actieveCategorie} setActieveCategorie={setActieveCategorie} />
        {errorMessage.length ? (
          <div className="bg-red-700 text-white p-3 rounded text-3xl text-center">
            Hier ging iets mis: {errorMessage}
          </div>
        ) : null}
        {isLoading ? (
          <Loading />
        ) : (
          <div className="border border-primary rounded m-2 bg-gray-100 p-5 flex flex-col items-center">
            {personen.map((persoon) => (
              <Persoon key={persoon._id} persoon={persoon} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
export default Personen

const Persoon = ({ persoon }: { persoon: PersoonsType }) => {
  const navigate = useNavigate()
  const handlePerson = () => {
    navigate('/person/' + persoon._id)
  }
  return (
    <button
      onClick={handlePerson}
      className="m-2 bg-primary hover:text-secondary text-white font-semibold py-2 px-4 border border-transparent hover:border-secondary rounded w-full text-center"
    >
      {persoon.profile.voornaam + ' ' + persoon.profile.achternaam}
      <FontAwesomeIcon icon={faPlay} className="mx-2" />
    </button>
  )
}
