import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from './Header'
import Loading from '../components/Loading'
import { PersoonsType } from '../Personen'
import { DateTime, Settings } from 'luxon'
import Planning from './Planning'
import { BASE_URL } from '../App'

export type Job = {
  beginuur: string
  einduur: string
  collegas?: string[]
  middel?: string
  project?: string
  buitenland?: boolean
  codeAntwerpen?: boolean
  code?: string
  description?: string
  opmerking?: string
}
export type DagPlanning = {
  datum: string
  gebruiker: string
  jobs?: Job[]
}
Settings.defaultLocale = 'nl'
Settings.defaultZone = 'Europe/Brussels'

const Persoon = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [datum, setDatum] = useState(DateTime.now())
  const [persoon, setPersoon] = useState<PersoonsType | undefined>(undefined)
  const [planning, setPlanning] = useState<DagPlanning[]>([])

  useEffect(() => {
    const getPlanning = async () => {
      try {
        const params = new URLSearchParams({
          slot: '4a9b',
          id: id || '',
          datum: datum.toISODate() || '',
        })
        const response = await fetch(BASE_URL + '/api/v1/planning/getpersoon?' + params, {
          method: 'GET',
        })
        if (response.status !== 200) {
          console.log('ERROR', response)
          setErrorMessage(response.statusText)
          setIsLoading(false)
          return
        }
        setErrorMessage('')
        const data = await response.json()
        setPersoon(data.user)
        if (data.planning && data.planning.constructor === Array) setPlanning(data.planning)
        setIsLoading(false)
      } catch (error) {
        console.log('ERROR', error)
      }
    }
    setIsLoading(true)
    getPlanning()
  }, [id, datum])

  return (
    <div className="flex flex-col items-center">
      <Header persoon={persoon} datum={datum} setDatum={setDatum} />
      {errorMessage.length ? (
        <div className="bg-red-700 text-white p-3 rounded text-3xl text-center">
          Hier ging iets mis: {errorMessage}
        </div>
      ) : null}
      {isLoading ? <Loading /> : <Planning planning={planning} datum={datum} />}
    </div>
  )
}
export default Persoon
