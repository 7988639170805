import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLarge } from '@fortawesome/free-solid-svg-icons'

const categories = ['camera', 'klank', 'montage', 'radio']

const Header = ({
  actieveCategorie,
  setActieveCategorie,
}: {
  actieveCategorie: string
  setActieveCategorie: Function
}) => {
  return (
    <div className="flex flex-col m-2">
      <div className="text-2xl text-center text-primary">Kies de dienst die je wil zien</div>
      <div className="flex flex-wrap justify-center m-2">
        {categories.map((categorie) =>
          categorie === actieveCategorie ? (
            <button
              key={categorie}
              className="m-2 bg-primary text-secondary font-semibold py-2 px-4 border border-transparent rounded"
            >
              {categorie}
              <FontAwesomeIcon className="mx-1" icon={faUserLarge} />
            </button>
          ) : (
            <button
              key={categorie}
              className="m-2 bg-transparent hover:bg-gray-200 text-primary font-semibold py-2 px-4 border border-primary rounded"
              onClick={() => {
                setActieveCategorie(categorie)
              }}
            >
              {categorie}
              <FontAwesomeIcon className="mx-1" icon={faUserLarge} />
            </button>
          )
        )}
      </div>
    </div>
  )
}
export default Header
